
import { from as observableFrom, BehaviorSubject, of, Observable, EMPTY } from 'rxjs';

import { take, switchMap, map } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ApplicationRef, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { Client, ClientExpense, ClientExpensesRequest, ClientWithRole } from '../models/Client';
import { DeleteClientComponent } from '../shared/dialogs/delete-client/delete-client.component';
import { Store } from '@ngrx/store';
import { State, getSelectedClient, getDashboardRole } from 'app/redux/reducers';
import { Alert } from 'app/models/alerts';
import { AlertType, ApplicationTypes } from 'app/shared/enums';
import { ExternalInvoiceService } from './external-invoice.service';
import { ApplicationAuthStatus } from 'app/models/approver';
import { AuthService } from './auth.service';
import { UUID } from 'angular2-uuid';
import { AddClientComponent } from 'app/clients/client-components/add-client/add-client.component';
import { AddEnterpriseClientComponent } from 'app/team/clients/add-enterprise-client/add-enterprise-client.component';
import { ClientCardDialogComponent } from 'app/clients/client-components/client-card-dialog/client-card-dialog.component';
import { EnterpriseClientCardDialogComponent } from 'app/team/clients/enterprise-client-card-dialog/enterprise-client-card-dialog.component';

@Injectable()
export class ClientService {
  _providerName: BehaviorSubject<string>;
  providerName$: Observable<string>;
  private baseUrl = `${environment.server}/client`;
  public CurrentCustomer: Client;
  public networkError;
  public NewCustomer: Client;
  private addClientDialogRef: MatDialogRef<AddClientComponent>;
  private openClientCardRef: MatDialogRef<ClientCardDialogComponent>;
  private openEnterpriseClientCardRef: MatDialogRef<EnterpriseClientCardDialogComponent>;
  private addEnterpriseClientDialogRef: MatDialogRef<AddEnterpriseClientComponent>;
  private deleteClientDiagRef: MatDialogRef<DeleteClientComponent>;

  constructor(
    private http: HttpClient,
    private dialogService: MatDialog,
    private appRef: ApplicationRef,
    private store: Store<State>,
    private externalService: ExternalInvoiceService,
    private authService: AuthService
  ) {
    this._providerName = new BehaviorSubject('');
    this.providerName$ = observableFrom(this._providerName);
  }

  public getSelectedClient(): Observable<Client> {
    return this.store.select(getSelectedClient).pipe(take(1))
  }

  public MergeClients(localClients: Client[], serverClients: Client[]): Client[] {
    // current behavior: treat the server data as "always right" if it exists
    // i.e., throw out local records and replace them with the newest server records
    if (serverClients !== null && serverClients !== undefined) {
      return serverClients;
    } else {
      return localClients;
    }
  }

  public checkForAlerts(clients: Client[]): Observable<Alert[]> {
    return this.externalService.getAuthStatus().pipe(
      take(1),
      map((status) => {
        const authStatus = new ApplicationAuthStatus();
        if (status) {
          status.forEach(provider => {
            authStatus[ApplicationTypes[provider]] = true;
          });
        }
        return authStatus;
      }),
      switchMap(status => {
        const authAlerts = clients
          .map((c) => {
            return {
              client: c,
              applications: Array.from(new Set(
                c.approvers.map((a) => a.appType).filter((val) => val !== null && val !== undefined && !status[val])
              ))
            }
          })
          .reduce((alerts, clientApplicationsTuple) => {
            return alerts.concat(clientApplicationsTuple.applications.map((app) => new Alert({
              type: AlertType.ApplicationConnection,
              name: clientApplicationsTuple.client.clientName,
              applicationType: app,
              clientId: clientApplicationsTuple.client.clientID
            })))
          }, new Array<Alert>())
        return of(authAlerts);
      })
    );
  }

  public GetAllLocalClients(): Observable<Client[]> {
    return of(new Array<Client>());
  }

  public GetAllClientsFromServer(): Observable<Client[]> {
    return this.http.get<Client[]>(`${this.baseUrl}/GetClients`);
  }

  public GetEnterpriseClientsFromServer(): Observable<Client[]> {
    return this.store.select(getDashboardRole).pipe(
      take(1),
      switchMap(role => this.http.get<Client[]>(`${this.baseUrl}/GetTeamClients?companyId=${role.companyId}`))
    )
  }

  public AddClients(cust: Client): Observable<Client> {
    return this.http.post<Client>(this.baseUrl, JSON.stringify(cust));
  }

  public UpsertClientsToLocalDb(clients: Client[] | Client): Observable<Client | Client[]> {
    return of(new Array<Client>());
  }

  public EditClient(client: Client): Observable<string> {
    return this.http.put(`${this.baseUrl}/EditClient`, JSON.stringify(client), {
      responseType: 'text'
    });
  }

  public UpdateClientsBilledRates(clients: Client[]): Observable<Client[]> {
    return this.http.post<Client[]>(`${this.baseUrl}/UpdateClientsBilledRates`, JSON.stringify(clients));
  }

  public getClientExpenses(requestBody: ClientExpensesRequest, companyId: string | UUID): Observable<ClientExpense[]> {
    return this.http.post<ClientExpense[]>(
      `${environment.server}/invoice/GetClientExpenses?companyId=${companyId}`,
      JSON.stringify(requestBody)
    );
  }

  public createNewClientDialog(action?: {}) {
    return new Observable(ob => {
      this.addClientDialogRef = this.dialogService.open(AddClientComponent, {
        panelClass: 'cartwheel-base-dialog',
        data: action ? action : null
      });
      ob.next('');
    });
  }

  public createNewEnterpriseClientDialog(action?: {}) {
    return new Observable(ob => {
      this.addEnterpriseClientDialogRef = this.dialogService.open(AddEnterpriseClientComponent, {
        panelClass: 'cartwheel-base-dialog',
        data: action ? action : null
      });
      ob.next('');
    });
  }

  public closeEnterpriseAddClientDialog(): Observable<{}> {
    return new Observable(ob => {
      if (!this.addEnterpriseClientDialogRef) {
        ob.error('There wasn\'t a dialog open');
      }
      this.addEnterpriseClientDialogRef.close();
      ob.next('');
    });
  }

  public showDeleteClientDialog(client: Client) {
    return new Observable(ob => {
      this.deleteClientDiagRef = this.dialogService.open(DeleteClientComponent, {
        width: '544px',
        data: client
      });
      ob.next('');
    });
  }

  public closeAddClientDialog(): Observable<{}> {
    return new Observable(ob => {
      if (!this.addClientDialogRef && !this.addEnterpriseClientDialogRef) {
        ob.error('There wasn\'t a dialog open');
      }
      if (this.addClientDialogRef) {
        this.addClientDialogRef.close();
      }
      if (this.addEnterpriseClientDialogRef) {
        this.addEnterpriseClientDialogRef.close();
      }
      ob.next('');
    });
  }

  public SaveClientToServer(clientRole: ClientWithRole): Observable<Client> {
    return this.http.post<Client>(`${this.baseUrl}/AddClient`, JSON.stringify(clientRole));
  }

  public showClientCard() {
    return new Observable(ob => {
      this.openClientCardRef = this.dialogService.open(ClientCardDialogComponent, {
        panelClass: 'cartwheel-base-dialog',
      });
      ob.next('');

    });
  }

  public showEnterpriseClientCard() {
    return new Observable(ob => {
      this.openEnterpriseClientCardRef = this.dialogService.open(EnterpriseClientCardDialogComponent, {
        panelClass: 'cartwheel-base-dialog',
      });
      ob.next('');

    });
  }

  public hideEnterpriseClientCard() {
    if (this.openEnterpriseClientCardRef) {
      this.openEnterpriseClientCardRef.close();
    }
    return EMPTY;
  }

  public hideClientCard() {
    if (this.openClientCardRef) {
      this.openClientCardRef.close();
    }
    return EMPTY;
  }

  public updateClientCardTableId() { }

  public activeProvider(provider: string) {
    this._providerName.next(provider.toLowerCase());
  }

  public downloadClientTimeSheetTemplate(clientId: string, companyId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${environment.server}/file/getclienttimesheettemplate?companyId=${companyId}&clientId=${clientId}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public downloadCompanyTimeSheetTemplate(companyId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${environment.server}/file/getcompanytimesheettemplate?companyId=${companyId}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public downloadTimeSheetExample(clientId: string, companyId: string): Observable<HttpResponse<Blob>> {
    return this.http.get(`${environment.server}/excel/exampletimesheet?companyId=${companyId}&clientId=${clientId}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  public uploadTimeSheetTemplate(file: File, clientId: string, companyId: string): Observable<string> {
    const formData = new FormData();
        formData.append('file', file, file.name);
    return this.http.post(
      `${environment.server}/file/uploadtimesheettemplate?companyId=${companyId}&clientId=${clientId}`,
      formData,
      { responseType: 'text' }
    );
  }
}
