
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-client-card-dialog',
  templateUrl: './client-card-dialog.component.html',
  styleUrls: ['./client-card-dialog.component.scss']
})
export class ClientCardDialogComponent {
  constructor(
    private dialog: MatDialogRef<ClientCardDialogComponent>,
  ) { }

  public closeDialog() {
    this.dialog.close();
  }

  public handleDialogStatus($event) {
    if ($event) {
      this.closeDialog();
    }
  }

  public handleApprover($event) { }
}
