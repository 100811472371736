import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { CartwheelButtonComponent, CartwheelIconButtonComponent } from '@cartwheel/web-components';
import { State } from '../../../redux/reducers';
import { Client } from '../../../models/Client';
import { EditClientAction } from '../../../redux/actions/client';
import { ClientStatus } from 'app/shared/enums';

@Component({
  standalone: true,
  selector: 'app-delete-client',
  templateUrl: './delete-client.component.html',
  styleUrls: ['./delete-client.component.scss'],
  imports: [CartwheelButtonComponent, CartwheelIconButtonComponent]
})
export class DeleteClientComponent {

  public client: Client;

  constructor(
    private store: Store<State>,
    private dialog: MatDialogRef<DeleteClientComponent>,

    @Inject(MAT_DIALOG_DATA) client: Client
  ) { this.client = client }

  public closeDialog() {
    this.dialog.close();
  }
  public deleteClient() {
    this.closeDialog();
    this.store.dispatch(new EditClientAction({ ...this.client, ...{ status: ClientStatus.Deleted } }));
  }
}
