import dayjs from 'dayjs';
import { takeWhile, withLatestFrom, skipWhile, filter } from 'rxjs/operators';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Inject,
  DestroyRef
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Store } from '@ngrx/store';
import { UUID } from 'angular2-uuid';
import { updateProjects, updateUserEarnings } from '../../client-shared-functions';
import { IsAdmin, filterCurrentWeekTasks } from '../../../shared/helpers';
import { Client } from '../../../models/Client';
import { Observable, combineLatest } from 'rxjs';
import { Approver } from '../../../models/approver';
import { Project } from '../../../models/project';
import { TimeEntry } from '../../../models/timeentry';
import { UserSettings } from '../../../models/user-settings';
import { InvoiceFrequency, Status, SubTabSelection } from '../../../shared/enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  EditClientAction,
  OpenDeleteClientDialogAction, UpdateClientCardSubTabAction
} from '../../../redux/actions/client';
import {
  getGSettingsGlobalSettingsModel,
  State,
  getUserProfileRoles,
  getSelectedClient,
  getProjectsList,
  getClientSubTab,
  getTimeEntries,
  getRunningTimeEntry,
  getTimerRunningState,
  getDashboardSelectedDateRange
} from '../../../redux/reducers/index';
import { getClientLoading } from '../../../redux/reducers';
import { ResponsiveService } from '../../../services/responsive.service';
import { ClientStatus, DayOfWeek, TimeOfDay, TimeRounding, SuccessStatus } from '../../../shared/enums';
import { GetAuthStatusAction } from '../../../redux/actions/approver';
import { UserCompanyRole } from '@cartwheel/web-components';
import { SelectProjectAction, LoadProjectsFromServerAction } from 'app/redux/actions/project';
import { OpenTimerDialogAction, EndTimerAction } from 'app/redux/actions/timer';
import { CartwheelSelectOptions } from '@cartwheel/web-components';

@Component({
  selector: 'app-client-card',
  templateUrl: './client-card.component.html',
  styleUrls: ['./client-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientCardComponent implements OnInit {
  @Input() onDashBoard: boolean;

  @Output() approver: EventEmitter<Approver> = new EventEmitter<Approver>();
  @Output() dialogStatus: EventEmitter<boolean> = new EventEmitter<boolean>();

  getGlobalSettingsModel$: Observable<UserSettings>;
  getGlobalSettingsModel = new UserSettings();

  removable = true;
  public projects: Array<Project>;
  public entries: TimeEntry[];
  public approvalForm: UntypedFormGroup;
  public editClient: UntypedFormGroup;
  public isMobile: boolean;
  public loading$: Observable<boolean | SuccessStatus>;
  private currentEntry$: Observable<TimeEntry>;
  private currentEntry: TimeEntry;
  public timerIsRunning$: Observable<boolean>;
  public error$: Observable<string>;
  public token$: Observable<string>;
  public provider$: Observable<UUID>;
  public providerName$: Observable<number>;
  private selectedSubTab$: Observable<SubTabSelection>;
  public activeSubTab: SubTabSelection;
  public selectedClient$: Observable<Client>;
  public selectedClient: Client = new Client();
  public projects$: Observable<Project[]>;
  public timeEntries$: Observable<TimeEntry[]>;
  public provider: UUID;
  public getClientLoading$: Observable<boolean | SuccessStatus>;
  public clientLoading: boolean | SuccessStatus;
  public isAdmin = true;
  private roles$: Observable<UserCompanyRole[]>;
  public averageWeeklyHours: number;
  public numOfActiveProjects: number;
  public clientProjects: Project[];
  public panelOpenState = false;
  public activeComponent = true;
  public error = '';
  public token = '';
  public providerName = -1;
  public itemCardTitles: string[] = ['Earnings This Week', 'Active Projects', 'Hours Worked This Week'];
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  public addOnBlur = true;
  public clientStatus = ClientStatus;
  public successStatus = SuccessStatus;
  public userCurrency: string;
  public dummyInvoiceData = [
    {
      invoiceNumber: '1',
      hours: '30',
      amountBilled: '3000',
      status: 'Ok'
    },
    {
      invoiceNumber: '2',
      hours: '3',
      amountBilled: '5000',
      status: 'Ok'
    }
  ];
  public timeRounding: CartwheelSelectOptions<TimeRounding> = [
    {
      label: 'Round to the nearest minute',
      value: TimeRounding.ToNearestMinute
    },
    {
      label: 'Round to the nearest quarter hour',
      value: TimeRounding.ToNearestQuarterHour
    },
    {
      label: 'Round to the nearest half hour',
      value: TimeRounding.ToNearestHalfHour
    },
    {
      label: 'Round to the nearest hour',
      value: TimeRounding.ToNearestHour
    },
    {
      label: 'Round up to the nearest minute',
      value: TimeRounding.UpToNearestMinute
    },
    {
      label: 'Round up to the nearest quarter hour',
      value: TimeRounding.UpToNearestQuarterHour
    },
    {
      label: 'Round up to the nearest half hour',
      value: TimeRounding.UpToNearestHalfHour
    },
    {
      label: 'Round up to the nearest hour',
      value: TimeRounding.UpToNearestHour
    },
    {
      label: 'Round down to the nearest minute',
      value: TimeRounding.DownToNearestMinute
    },
    {
      label: 'Round down to the nearest quarter hour',
      value: TimeRounding.DownToNearestQuarterHour
    },
    {
      label: 'Round down to the nearest half hour',
      value: TimeRounding.DownToNearestHalfHour
    },
    {
      label: 'Round down to the nearest hour',
      value: TimeRounding.DownToNearestHour
    }
  ];

  public reportDay: CartwheelSelectOptions<DayOfWeek> = [
    {
      label: 'Monday',
      value: DayOfWeek.Monday
    },
    {
      label: 'Tuesday',
      value: DayOfWeek.Tuesday
    },
    {
      label: 'Wednesday',
      value: DayOfWeek.Wednesday
    },
    {
      label: 'Thursday',
      value: DayOfWeek.Thursday
    },
    {
      label: 'Friday',
      value: DayOfWeek.Friday
    },
    {
      label: 'Saturday',
      value: DayOfWeek.Saturday
    },
    {
      label: 'Sunday',
      value: DayOfWeek.Sunday
    }
  ];

  public sendInvoiceInterval = [
    {
      name: 'Bi-weekly on the 1st and 15th',
      value: InvoiceFrequency.BiWeeklyOnTheFirstAndFifteenth
    },
    {
      name: 'At the end of the month',
      value: InvoiceFrequency.AtTheEndOfTheMonth
    },
    {
      name: 'Every Friday',
      value: InvoiceFrequency.EveryFriday
    }
  ];

  public timeOfDay: CartwheelSelectOptions<TimeOfDay> = [
    {
      label: '7 am',
      value: TimeOfDay.SevenAM
    },
    {
      label: '8 am',
      value: TimeOfDay.EightAM
    },
    {
      label: '9 am',
      value: TimeOfDay.NineAM
    },
    {
      label: '10 am',
      value: TimeOfDay.TenAM
    },
    {
      label: '2 pm',
      value: TimeOfDay.TwoPM
    },
    {
      label: '4 pm',
      value: TimeOfDay.FourPM
    }
  ];

  constructor(
    @Inject(ChangeDetectorRef) private ref: ChangeDetectorRef,
    @Inject(Store) private store: Store<State>,
    private responsiveService: ResponsiveService,
    private destroyRef: DestroyRef,
    @Inject(UntypedFormBuilder) private fb: UntypedFormBuilder
  ) { }

  ngOnInit() {
    this.editClient = this.initialiseEditClientForm();
    this.approvalForm = this.initialiseApproverFormArray();
    this.store.dispatch(new GetAuthStatusAction());
    this.getClientLoading$ = this.store.select(getClientLoading);
    this.selectedClient$ = this.store.select(getSelectedClient).pipe(skipWhile(sc => !sc));
    this.projects$ = this.store.select(getProjectsList);
    this.timeEntries$ = this.store.select(getTimeEntries);
    this.getGlobalSettingsModel$ = this.store.select(getGSettingsGlobalSettingsModel);
    this.selectedSubTab$ = this.store.select(getClientSubTab);
    this.roles$ = this.store.select(getUserProfileRoles).pipe(skipWhile((roles) => !roles));
    this.currentEntry$ = this.store.select(getRunningTimeEntry);
    this.timerIsRunning$ = this.store.select(getTimerRunningState);

    this.subscribeToWindowSize();

    this.getGlobalSettingsModel$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe((gModel: UserSettings) => {
        this.getGlobalSettingsModel = new UserSettings(gModel);
        this.userCurrency = this.getGlobalSettingsModel.currency;
      });

    this.selectedSubTab$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(subTab => {
        this.activeSubTab = subTab;
      });

    this.getClientLoading$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe((loading: boolean | SuccessStatus) => {
        this.clientLoading = loading;
      });

    this.currentEntry$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe((entry: TimeEntry) => {
        this.currentEntry = entry;
      });


    combineLatest([this.selectedClient$, this.projects$, this.timeEntries$, this.roles$])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(([sc, p, e, r]) => !!sc),
        withLatestFrom(this.store.select(getDashboardSelectedDateRange)),
      )
      .subscribe(([[selectedClient, projects, timeEntries, roles], selectedDateRange]) => {
        this.isAdmin = (roles) ? IsAdmin(roles, selectedClient.createdByCompanyId) : false;

        const filteredEntries = filterCurrentWeekTasks(
          selectedDateRange as [Date, Date],
          timeEntries.filter(timeEntry => {
            return timeEntry && timeEntry.status !== Status.Deleted && timeEntry.clientId === selectedClient.clientID
          })
        );

        const updatedProjects = updateProjects(
          selectedClient,
          projects.filter(p => p.clientId === selectedClient.clientID),
          filteredEntries,
          roles.find(r => r.companyId === selectedClient.createdByCompanyId).hourlyRate,
          true
        );

        const [updatedSelectedClient, averageWeeklyHours] = updateUserEarnings(
          selectedClient,
          filteredEntries,
          roles
        );

        this.selectedClient = updatedSelectedClient;
        this.averageWeeklyHours = Number(averageWeeklyHours.toFixed(3));
        this.numOfActiveProjects = updatedProjects.length;
        this.clientProjects = updatedProjects;
        this.editClient = this.initialiseEditClientForm();
        this.ref.markForCheck();
      });
  }

  private initialiseApproverFormArray(): UntypedFormGroup {
    return new UntypedFormGroup({
      approvers: new UntypedFormArray([])
    });
  }

  private initialiseEditClientForm(): UntypedFormGroup {
    const form = this.fb.group({
      billedRate: [{
        value: this.selectedClient.billedRate ? this.selectedClient.billedRate : null,
        disabled: !this.isAdmin
      },
      [Validators.required]
      ],
      timeRounding: [{
        value: this.selectedClient.timeRounding !== null &&
          this.selectedClient.timeRounding !== undefined ?
          this.selectedClient.timeRounding : null,
        disabled: !this.isAdmin
      },
      [Validators.required]
      ],
      reportDay: [{
        value: this.selectedClient.reportDay !== null &&
          this.selectedClient.reportDay !== undefined ? this.selectedClient.reportDay : null,
        disabled: !this.isAdmin
      },
      [Validators.required]
      ],
      timeOfDay: [{
        value: this.selectedClient.timeOfDay !== null &&
          this.selectedClient.timeOfDay !== undefined ? this.selectedClient.timeOfDay : null,
        disabled: !this.isAdmin
      },
      [Validators.required]
      ],
      invoiceFrequency: [{
        value: this.selectedClient.invoiceFrequency !== null &&
          this.selectedClient.invoiceFrequency !== undefined ? this.selectedClient.invoiceFrequency : null,
        disabled: !this.isAdmin
      },
      [Validators.required]
      ],
      sendClientInvoice: [{
        value: this.selectedClient.invoiceEmails && this.selectedClient.invoiceEmails.length > 0 ? true : false,
        disabled: !this.isAdmin
      }
      ]
    });

    const sendClientInvoiceControl = form.get('sendClientInvoice');
    sendClientInvoiceControl.valueChanges
      .pipe(takeWhile(() => this.activeComponent))
      .subscribe((sendClientInvoices: boolean) => {
        if (sendClientInvoices) {
          form.setControl('invoiceEmails', new UntypedFormControl(
            {
              value: this.selectedClient.invoiceEmails ? this.selectedClient.invoiceEmails : [],
              disabled: !this.isAdmin
            },
            [Validators.required]
          ));
          form.setControl('invoiceEmail', new UntypedFormControl(null))
        } else {
          form.removeControl('invoiceEmails');
          form.removeControl('invoiceEmail');
        }
      })

    // needed to trigger logic to set invoice emails
    sendClientInvoiceControl.setValue(sendClientInvoiceControl.value);
    return form;
  }

  public add(matChipName: string, event: MatChipInputEvent): void {
    const { chipInput, value } = event;
    const invoiceEmails = this.editClient.get('invoiceEmails');
    const invoiceEmailsInput = this.editClient.get('invoiceEmail');
    if (matChipName === 'invoiceEmail' && (value || '').trim() && !invoiceEmailsInput.errors) {
      const existingInvoiceEmails: string[] = invoiceEmails.value.concat(value.trim());
      invoiceEmails.setValue(existingInvoiceEmails);
      if (chipInput) {
        chipInput.clear();
        invoiceEmailsInput.setValue('');
      }
    }
  }

  public openStartTimerDialog(project: Project) {
    this.store.dispatch(new SelectProjectAction(project));
    this.store.dispatch(new OpenTimerDialogAction());
  }

  public stopTimer() {
    const currentTime = dayjs().toDate();
    const entry = new TimeEntry(Object.assign({
      ...this.currentEntry,
      endTime: currentTime
    }));
    this.store.dispatch(new EndTimerAction(entry));
    this.store.dispatch(new LoadProjectsFromServerAction(null));
    this.store.dispatch(new SelectProjectAction(undefined));
  }

  public remove(matChipName: string, index: number): void {
    if (matChipName === 'invoiceEmail') {
      const invoiceEmails = this.editClient.get('invoiceEmails');
      if (index >= 0) {
        const existingInvoiceEmails = [
          ...invoiceEmails.value.slice(0, index),
          ...invoiceEmails.value.slice(index + 1)
        ];
        invoiceEmails.setValue(existingInvoiceEmails);
        this.editClient.markAsDirty();
      }
    }
  }

  public save() {
    const sendClientInvoiceControl = this.editClient.get('sendClientInvoice');
    const client = new Client({
      ...this.selectedClient,
      approvers: this.approvalForm.get('approvers').value,
      billedRate: this.editClient.get('billedRate').value,
      timeRounding: this.editClient.get('timeRounding').value,
      invoiceEmails: (sendClientInvoiceControl.value) ? this.editClient.get('invoiceEmails').value : [],
      timeOfDay: this.editClient.get('timeOfDay').value,
      invoiceFrequency: this.editClient.get('invoiceFrequency').value,
      reportDay: this.editClient.get('reportDay').value
    });
    this.store.dispatch(new EditClientAction(client));
  }

  private subscribeToWindowSize() {
    this.responsiveService.getMobileStatus()
      .pipe(takeWhile(() => this.activeComponent))
      .subscribe(status => this.isMobile = status);
  }

  public updateForm(approvalForm: UntypedFormGroup) {
    this.approvalForm = approvalForm
  }

  public showDeleteClientDialog() {
    this.store.dispatch(new OpenDeleteClientDialogAction(this.selectedClient));
  }

  public tabChanged({ index }): void {
    this.activeSubTab = index;
    this.store.dispatch(new UpdateClientCardSubTabAction(this.activeSubTab))
  }

  public close() {
    this.dialogStatus.emit(true);
  }
}
