<div class="block w-full h-auto bg-white rounded-md shadow-card leading-1 text-14 p-23">
  <div class="flex items-start gap-10 pb-26">
    <div class="flex flex-1">
      <div
        class="items-center flex-1 overflow-x-hidden font-medium text-darkerGray text-24 text-ellipsis whitespace-nowrap leading-30"
      >
        {{ selectedClient?.clientName }}
      </div>
      @if (selectedClient?.clientName && isAdmin) {
        <cartwheel-icon-button
          class="float-right"
          matIconName="delete"
          type="outline-button"
          color="danger"
          size="sm"
          (onClick)="showDeleteClientDialog()"
        />
      }
    </div>
    @if (isMobile) {
      <cartwheel-icon-button class="float-right" matIconName="close" color="darkGray" size="sm" (onClick)="close()" />
    }
  </div>

  <div>
    <app-earnings-summary
      [earnings]="selectedClient?.monthEarnings"
      [billedHours]="numOfActiveProjects"
      [numberOfTasks]="averageWeeklyHours"
      [titles]="itemCardTitles"
      parentName="client-card"
      [globalSettingsModel]="getGlobalSettingsModel"
    />

    <mat-tab-group class="card-tabs mt-13" disableRipple [selectedIndex]="activeSubTab" (selectedTabChange)="tabChanged($event)">
      <mat-tab label="Settings" />
      <mat-tab label="Projects" />
    </mat-tab-group>
  </div>

  @switch (activeSubTab) {
    @case (0) {
      <form class="pt-30" [formGroup]="editClient">
        <div class="grid grid-cols-1 gap-20">
          @if (isAdmin) {
            <div class="grid items-end grid-cols-2 gap-20">
              <cartwheel-input
                label="BILLED RATE"
                type="number"
                [inputFormControl]="editClient.get('billedRate') | formControlType"
              />
              <cartwheel-input placeholder="task hourly rate" [disabled]="true" value="" />
            </div>

            <cartwheel-select
              label="Time Tracking Rounding Rules"
              [options]="timeRounding"
              [selectFormControl]="editClient.get('timeRounding') | formControlType"
            />
          }
          <app-client-approvers
            [selectedClient]="selectedClient"
            [isAdmin]="isAdmin"
            [isMobile]="isMobile"
            [approvalForm]="approvalForm"
            (approvalFormEmitter)="updateForm($event)"
            (saveClient)="save()"
          >
          </app-client-approvers>

          @if (false) {
            <div class="grid items-end grid-cols-2 gap-20">
              <cartwheel-select
                label="SEND REPORTS AT"
                [options]="reportDay"
                [selectFormControl]="editClient.get('reportDay') | formControlType"
              />
              <cartwheel-select
                [options]="timeOfDay"
                [selectFormControl]="editClient.get('timeOfDay') | formControlType"
              />
            </div>

            <div class="flex items-center gap-5 py-10">
              <label class="font-medium uppercase text-12 text-darkGray">Send Client Invoice?</label>
              <mat-checkbox [disabled]="!isAdmin" color="primary" formControlName="sendClientInvoice" />
            </div>
          }

          @if (onDashBoard && isAdmin) {
            <cartwheel-button
              class="w-150"
              label="Save"
              statusButton
              color="secondary"
              [status]="
                (getClientLoading$ | async) === true || (getClientLoading$ | async) === successStatus.IsLoading
                  ? successStatus.IsLoading
                  : ((getClientLoading$ | async) === successStatus.Success && successStatus.Success) ||
                    ((getClientLoading$ | async) === successStatus.Error && successStatus.Error) ||
                    ((approvalForm.invalid || (approvalForm.pristine && editClient.pristine) || editClient.invalid) &&
                      successStatus.Disabled) ||
                    successStatus.Enabled
              "
              (onClick)="save()"
            />
          }
        </div>
      </form>
    }
    @case (1) {
      <table>
        <tr>
          <th>Name</th>
          <th>Billed Hours</th>
          <th>Earnings</th>
          <th>Timer</th>
        </tr>
        @for (project of clientProjects; track 'project' + $index) {
          <tr>
            <td>{{ project.projectName }}</td>
            <td>{{ project.projectBilledHours | number : '1.2-2' }}</td>
            <td>{{ project.projectEarnings | currency: userCurrency : 'symbol' : '1.2-2' }}</td>
            <td>
              @if (timerIsRunning$ | async) {
                <cartwheel-button label="Stop" type="text-button" size="sm" color="secondary" (onClick)="stopTimer()" />
              } @else {
                <cartwheel-button
                  label="Start"
                  type="text-button"
                  size="sm"
                  color="secondary"
                  (onClick)="openStartTimerDialog(project)"
                />
              }
            </td>
          </tr>
        }
      </table>
    }
  }
</div>
