import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatStepper } from '@angular/material/stepper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Action, select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { Client } from '../../../models/Client';
import { SubmitClientAction } from '../../../redux/actions/client';
import { getDashboardRole, State } from '../../../redux/reducers/index';
import { DayOfWeek, InvoiceFrequency, TimeOfDay, TimeRounding } from '../../../shared/enums';
import { ResponsiveService } from '../../../services/responsive.service';
import { PATTERNS } from 'app/shared/consts';
import { CartwheelSelectOptions, SuccessStatus } from '@cartwheel/web-components';

@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss'],
})
export class AddClientComponent implements OnInit {
  public newClient: Client;
  public addClientForm: UntypedFormGroup;
  public basicInfo: UntypedFormGroup;
  public reportInterval: UntypedFormGroup;
  public selectedClient: Client;
  public approvalForm: UntypedFormGroup;
  public isLinear = true;
  public componentFloat = false;
  public successStatus = SuccessStatus;
  private action: Action;
  approval: boolean;
  isMobile: boolean;
  visible = true;
  activeComponent = true;
  // selectable = true;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  validateDecimal = '[0-9]\\d*(\\.\\d+)?';
  timeRounding: CartwheelSelectOptions<TimeRounding> = [
    {
      label: 'Round to the nearest minute',
      value: TimeRounding.ToNearestMinute
    },
    {
      label: 'Round to the nearest quarter hour',
      value: TimeRounding.ToNearestQuarterHour
    },
    {
      label: 'Round to the nearest half hour',
      value: TimeRounding.ToNearestHalfHour
    },
    {
      label: 'Round to the nearest hour',
      value: TimeRounding.ToNearestHour
    },
    {
      label: 'Round up to the nearest minute',
      value: TimeRounding.UpToNearestMinute
    },
    {
      label: 'Round up to the nearest quarter hour',
      value: TimeRounding.UpToNearestQuarterHour
    },
    {
      label: 'Round up to the nearest half hour',
      value: TimeRounding.UpToNearestHalfHour
    },
    {
      label: 'Round up to the nearest hour',
      value: TimeRounding.UpToNearestHour
    },
    {
      label: 'Round down to the nearest minute',
      value: TimeRounding.DownToNearestMinute
    },
    {
      label: 'Round down to the nearest quarter hour',
      value: TimeRounding.DownToNearestQuarterHour
    },
    {
      label: 'Round down to the nearest half hour',
      value: TimeRounding.DownToNearestHalfHour
    },
    {
      label: 'Round down to the nearest hour',
      value: TimeRounding.DownToNearestHour
    }
  ];

  reportDay: CartwheelSelectOptions<DayOfWeek> = [
    {
      label: 'Monday',
      value: DayOfWeek.Monday
    },
    {
      label: 'Tuesday',
      value: DayOfWeek.Tuesday
    },
    {
      label: 'Wednesday',
      value: DayOfWeek.Wednesday
    },
    {
      label: 'Thursday',
      value: DayOfWeek.Thursday
    },
    {
      label: 'Friday',
      value: DayOfWeek.Friday
    },
    {
      label: 'Saturday',
      value: DayOfWeek.Saturday
    },
    {
      label: 'Sunday',
      value: DayOfWeek.Sunday
    }
  ];

  timeOfDay: CartwheelSelectOptions<TimeOfDay> = [
    {
      label: '7 am',
      value: TimeOfDay.SevenAM
    },
    {
      label: '8 am',
      value: TimeOfDay.EightAM
    },
    {
      label: '9 am',
      value: TimeOfDay.NineAM
    },
    {
      label: '10 am',
      value: TimeOfDay.TenAM
    },
    {
      label: '2 pm',
      value: TimeOfDay.TwoPM
    },
    {
      label: '4 pm',
      value: TimeOfDay.FourPM
    }
  ];

  public invoiceFrequencies: CartwheelSelectOptions<InvoiceFrequency> = [
    {
      label: 'Bi-weekly on the 1st and 15th',
      value: InvoiceFrequency.BiWeeklyOnTheFirstAndFifteenth
    },
    {
      label: 'At the end of the month',
      value: InvoiceFrequency.AtTheEndOfTheMonth
    },
    {
      label: 'Every Friday',
      value: InvoiceFrequency.EveryFriday
    }
  ];

  public billedRateOptions: CartwheelSelectOptions<string> = [
    { label: 'Task Hourly Rate', value: 'hourly' }
  ]

  constructor(
    public dialog: MatDialogRef<AddClientComponent>,
    private responsiveService: ResponsiveService,
    private store: Store<State>,
    @Inject(MAT_DIALOG_DATA) data: any,
    private destroyRef: DestroyRef
  ) {
    this.action = data;
  }

  ngOnInit() {
    this.approvalForm = new UntypedFormGroup({
      approvers: new UntypedFormArray([])
    });

    this.responsiveService.getMobileStatus()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(status => {
        this.isMobile = status;
      });

    this.basicInfo = new UntypedFormGroup({
      clientName: new UntypedFormControl('', Validators.required),
      createdByCompanyId: new UntypedFormControl(''),
      billedRate: new UntypedFormControl(0, [Validators.required, Validators.pattern(this.validateDecimal)]),
      billedOn: new UntypedFormControl('hourly'),
      timeRounding: new UntypedFormControl(TimeRounding.ToNearestMinute, Validators.required)
    });

    this.reportInterval = new UntypedFormGroup({
      // create some object to store this information
      invoiceEmails: new UntypedFormControl([]),
      reportDay: new UntypedFormControl(DayOfWeek.Monday, Validators.required),
      timeOfDay: new UntypedFormControl(TimeOfDay.NineAM, Validators.required),
      invoiceFrequency: new UntypedFormControl(InvoiceFrequency.BiWeeklyOnTheFirstAndFifteenth, Validators.required),
      sendClientInvoice: new UntypedFormControl(false)
    });

    const sendClientInvoiceControl = this.reportInterval.get('sendClientInvoice');
    sendClientInvoiceControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((sendClientInvoices: boolean) => {
        if (sendClientInvoices) {
          this.reportInterval.setControl('invoiceEmails', new UntypedFormControl([], [Validators.required]));
          this.reportInterval.get('invoiceEmails')
            .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((emails: string[]) => {
              const isInvalid = emails.some(email => !PATTERNS.Email.test(email));
              this.reportInterval.get('invoiceEmails').setErrors(isInvalid ? { email: true } : null);
            })
        } else {
          this.reportInterval.removeControl('invoiceEmails');
        }
      })

    // needed to trigger logic to set invoice emails
    sendClientInvoiceControl.setValue(sendClientInvoiceControl.value);

    this.dialog.afterClosed().subscribe(hidden => {
      if (this.action) {
        this.store.dispatch(this.action);
      }
    });
  }

  onNext(stepper: MatStepper) {
    // let promise = service.validateData();
    if (stepper.selectedIndex === 0) {
      this.markFormGroupTouched(this.basicInfo);
    }
    stepper.next();
  }

  onPrev(stepper: MatStepper) {
    // let promise = service.validateData();
    stepper.previous();
  }

  // Marks all controls in a form group as touched
  private markFormGroupTouched(formGroup: UntypedFormGroup) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

  public add(matChipName: string, event: MatChipInputEvent): void {
    const { chipInput, value } = event;
    const invoiceEmails = this.reportInterval.get('invoiceEmails');
    if (matChipName === 'invoiceEmail' && (value || '').trim()) {
      const existingInvoiceEmails: string[] = invoiceEmails.value.concat(value.trim());
      invoiceEmails.setValue(existingInvoiceEmails);
      if (chipInput) {
        chipInput.clear();
      }
    }
  }


  public remove(matChipName: string, index: number): void {
    if (matChipName === 'invoiceEmail') {
      const invoiceEmails = this.reportInterval.get('invoiceEmails');
      if (index >= 0) {
        const existingInvoiceEmails = [
          ...invoiceEmails.value.slice(0, index),
          ...invoiceEmails.value.slice(index + 1)
        ];
        invoiceEmails.setValue(existingInvoiceEmails);
        this.reportInterval.markAsDirty();
      }
    }
  }

  public closeDialog() {
    // just added this to make the build process stop complaining.
    this.dialog.close();
  }

  public saveClient() {
    // this.approvalForm.value;
    const testClient = {};
    this.store.pipe(
      select(getDashboardRole),
      take(1)
    ).subscribe(role => {
      Object.assign(testClient, this.approvalForm.value);
      Object.assign(testClient, this.basicInfo.value);
      Object.assign(testClient, this.reportInterval.value);
      Object.assign(testClient, { 'creationDateTime': new Date() });
      this.newClient = new Client(testClient);
      this.newClient.createdByCompanyId = role.companyId.toString();
      this.store.dispatch(new SubmitClientAction({ client: this.newClient, role: role }));
    });
  }

  public updateForm(approvalForm: UntypedFormGroup) {
    this.approvalForm = approvalForm
  }
}
