<div class="addClientContainer">
  <form #dialog>
    <div class="dialog-body">
      <div class="dialog-header">
        <span class="dialog-header-text">Enter Client Information</span>
        <cartwheel-icon-button matIconName="clear" class="dialog-close-btn" (onClick)="closeDialog()" />
      </div>
    </div>

    <div class="dialog-content">
      <mat-stepper [linear]="isLinear" [orientation]="isMobile ? 'vertical' : 'horizontal'" #stepper>
        <mat-step [stepControl]="basicInfo" [completed]="false">
          <form [formGroup]="basicInfo">
            <ng-template matStepLabel>Details</ng-template>

            <div class="stepper-content">
              <cartwheel-input
                label="Client Name (required)"
                [inputFormControl]="basicInfo.get('clientName') | formControlType"
              />

              <div class="flex flex-col">
                <label class="mb-4 font-medium uppercase text-darkGray text-12">Billed Rate</label>

                <div class="grid grid-cols-12 gap-10">
                  <cartwheel-input
                    class="col-span-5 tablet:col-span-8"
                    [inputFormControl]="basicInfo.get('billedRate') | formControlType"
                  />
                  <cartwheel-select
                    class="col-span-7 tablet:col-span-4"
                    [options]="billedRateOptions"
                    [selectFormControl]="basicInfo.get('billedOn') | formControlType"
                  />
                </div>
              </div>

              <cartwheel-select
                label="Time Rounding"
                [options]="timeRounding"
                [selectFormControl]="basicInfo.get('timeRounding') | formControlType"
              />
            </div>

            <hr class="my-20" />

            <div class="stepper-footer">
              <cartwheel-button
                class="[&>button]:px-40"
                label="Next"
                color="secondary"
                (onClick)="onNext(stepper)"
                [status]="basicInfo.invalid && successStatus.Disabled"
              />
              <cartwheel-button label="Cancel" type="text-button" color="middleGray" (onClick)="closeDialog()" />
            </div>
          </form>
        </mat-step>

        <!-- Approver Step -->
        <mat-step [stepControl]="approvalForm" [completed]="false">
          <div>
            <ng-template matStepLabel>Approver</ng-template>

            @if (approvalForm) {
              <app-client-approvers
                [selectedClient]="selectedClient"
                [isAdmin]="isMobile"
                [isMobile]="isMobile"
                [approvalForm]="approvalForm"
                (approvalFormEmitter)="updateForm($event)"
              />
            }

            <div class="stepper-footer mt-15">
              <cartwheel-button
                class="[&>button]:px-40"
                label="Next"
                color="secondary"
                (onClick)="onNext(stepper)"
                [status]="basicInfo.invalid && successStatus.Disabled"
              />
              <cartwheel-button label="Previous" type="text-button" color="middleGray" (onClick)="onPrev(stepper)" />
            </div>
          </div>
        </mat-step>

        <!-- Report Step -->
        <mat-step [stepControl]="reportInterval" [completed]="false">
          <form [formGroup]="reportInterval">
            <ng-template matStepLabel>Report</ng-template>

            <div class="stepper-content">
              <div>
                <label class="mb-4 font-medium uppercase text-darkGray text-12">SEND REPORTS AT</label>

                <div class="grid grid-cols-2 gap-10">
                  <cartwheel-select
                    [options]="reportDay"
                    [selectFormControl]="reportInterval.get('reportDay') | formControlType"
                  />
                  <cartwheel-select
                    [options]="timeOfDay"
                    [selectFormControl]="reportInterval.get('timeOfDay') | formControlType"
                  />
                </div>

                <span class="text-12 text-darkGray"
                  >(Dont worry we will remind you before we send them and you can change this later)</span
                >
              </div>

              <cartwheel-select
                label="Send Invoices"
                [options]="invoiceFrequencies"
                [selectFormControl]="reportInterval.get('invoiceFrequency') | formControlType"
              />

              <div class="flex flex-col gap-5">
                <div class="flex items-center gap-5">
                  <label class="font-medium uppercase text-12 text-darkGray">Send Client Invoice?</label>
                  <mat-checkbox color="primary" formControlName="sendClientInvoice"> </mat-checkbox>
                </div>

                @if (reportInterval.contains('invoiceEmails')) {
                  <div>
                    <cartwheel-chip-grid [chipFormControl]="reportInterval.get('invoiceEmails') | formControlType" />

                    @if (reportInterval.get('invoiceEmails').errors?.required) {
                      <span class="text-danger text-12">Please enter email address</span>
                    } @else if (reportInterval.get('invoiceEmails').errors?.email) {
                      <span class="text-danger text-12">Please enter valid email address</span>
                    }
                  </div>
                }
              </div>
            </div>

            <hr class="my-20" />

            <div class="stepper-footer">
              <cartwheel-button
                class="[&>button]:px-40"
                label="Done"
                color="secondary"
                (onClick)="saveClient()"
                [status]="reportInterval.invalid && successStatus.Disabled"
              />
              <cartwheel-button label="Previous" type="text-button" color="middleGray" (onClick)="onPrev(stepper)" />
            </div>
          </form>
        </mat-step>
      </mat-stepper>
    </div>
  </form>
</div>
